/* Container styling */
.admin-details-container {
  background-color: #f5f5f5;  /* Light background */
  min-height: 100vh;          /* Full height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
  padding: 20px;
}

/* Title styling */
h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

/* Table styling */
.admin-table {
  width: 80%;
  max-width: 800px;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Table header */
.admin-table th {
  background-color: #007bff;
  color: white;
  padding: 12px 15px;
  text-align: left;
  font-size: 1.2rem;
}

/* Table rows */
.admin-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
  font-size: 1rem;
}

/* Alternating row colors for readability */
.admin-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Hover effect on table rows */
.admin-table tr:hover {
  background-color: #f1f1f1;
}
.admin-details-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
}

.admin-table th,
.admin-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.admin-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  font-size: 0.85rem;
}

.admin-table tr:last-child td {
  border-bottom: none;
}

.admin-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.admin-table tr:hover {
  background-color: #f5f5f5;
  transition: background-color 0.3s ease;
}

.error {
  color: #ff0000;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .admin-table {
    font-size: 0.85rem;
  }

  .admin-table th,
  .admin-table td {
    padding: 8px 10px;
  }

  .admin-table th {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .admin-details-container {
    padding: 10px;
  }

  .admin-table {
    font-size: 0.75rem;
  }

  .admin-table th,
  .admin-table td {
    padding: 6px 8px;
  }

  .admin-table th {
    font-size: 0.7rem;
  }
}