.navbar {
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.3s ease-out;
  }
  
  .navbar-brand span {
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .nav-link {
    position: relative;
    overflow: hidden;
  }
  
  .nav-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #FE6B8B;
    transform: scaleX(0);
    transition: transform 0.3s ease-out;
  }
  
  .nav-link:hover::after {
    transform: scaleX(1);
  }
  
  .social-icon {
    font-size: 1.5rem;
    color: #fff;
    transition: color 0.3s ease-out;
  }
  
  .social-icon:hover {
    color: #FE6B8B;
  }
  
  .footer-section {
    background: linear-gradient(45deg, #2C3E50, #000000);
  }