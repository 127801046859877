.contact-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #e0f7fa 0%, #e8eaf6 100%);
    padding: 20px;
  }
  
  .contact-form-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 100%;
    max-width: 500px;
  }
  
  .contact-title {
    font-size: 2.5rem;
    color: #3f51b5;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    position: relative;
  }
  
  .input-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #3f51b5;
  }
  
  input, textarea {
    width: 100%;
    padding: 12px 40px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  input:focus, textarea:focus {
    outline: none;
    border-color: #3f51b5;
  }
  
  textarea {
    resize: vertical;
  }
  
  .submit-button {
    background-color: #3f51b5;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #303f9f;
  }
  
  .button-icon {
    margin-right: 10px;
  }
  
  .contact-info {
    margin-top: 30px;
    text-align: center;
    color: #616161;
  }
  
  .contact-info p {
    margin: 5px 0;
  }
  
  @media (max-width: 600px) {
    .contact-form-container {
      padding: 30px;
    }
  
    .contact-title {
      font-size: 2rem;
    }
  }
  .submit-status {
    margin-top: 1rem;
    padding: 0.5rem;
    border-radius: 4px;
    text-align: center;
  }
  
  .submit-status.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .submit-status.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  /* Custom styles for toast notifications */
.Toastify__toast-container {
  width: 320px;
}

.Toastify__toast--success {
  background: #e2e8e2;
}

.Toastify__toast--error {
  background: #f44336;
}

.Toastify__toast-body {
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 5px;
}

.Toastify__progress-bar {
  background: rgba(255, 255, 255, 0.7);
}